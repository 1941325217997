import React from 'react';
import { graphql } from 'gatsby';
import Section from '../components/layout/Section';
import { Helmet } from 'react-helmet';

export default function Template({ data }:{ data:any }) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return <>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>{frontmatter.title}</title>
    </Helmet>
    <Section id="support-post">
      <h1>{frontmatter.title}</h1>
      <div className="support-post-content" dangerouslySetInnerHTML={{ __html: html }} />
    </Section>
  </>;
}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;
